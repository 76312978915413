import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { Image } from 'components'
import ReactCompareImage from 'react-compare-image'

import ba1 from 'data/img/before-and-after/before_after1.jpg'
import ba2 from 'data/img/before-and-after/before_after2.jpg'

// import ba3 from 'data/img/before-and-after/IMG_0875.jpg'
// import ba4 from 'data/img/before-and-after/IMG_0873.jpg'

import ba3 from 'data/img/before-and-after/before_after3.jpg'
import ba4 from 'data/img/before-and-after/before_after4.jpg'

const BeforeAndAfter = props => {
  //   const { ba1 } = useStaticQuery(
  //     graphql`
  //       query {
  //         ba1: file(relativePath: { eq: "img/before-and-after/IMG_0872.jpg" }) {
  //           childImageSharp {
  //             fluid {
  //               ...GatsbyImageSharpFluid
  //             }
  //           }
  //         }
  //       }
  //     `
  //   )
  //   return (
  //     <>
  //       <ReactCompareImage leftImage={ba3} rightImage={ba4} />
  //       <ReactCompareImage leftImage={ba1} rightImage={ba2} />
  //     </>
  //   )
  return [
    // <ReactCompareImage leftImage={ba3} rightImage={ba4} />,
    <ReactCompareImage leftImage={ba1} rightImage={ba2} />,
  ]
}

export const BeforeAndAfter2 = props => {
  //   const { ba1 } = useStaticQuery(
  //     graphql`
  //       query {
  //         ba1: file(relativePath: { eq: "img/before-and-after/IMG_0872.jpg" }) {
  //           childImageSharp {
  //             fluid {
  //               ...GatsbyImageSharpFluid
  //             }
  //           }
  //         }
  //       }
  //     `
  //   )
  //   return (
  //     <>
  //       <ReactCompareImage leftImage={ba3} rightImage={ba4} />
  //       <ReactCompareImage leftImage={ba1} rightImage={ba2} />
  //     </>
  //   )
  return <ReactCompareImage leftImage={ba3} rightImage={ba4} />
}

export default BeforeAndAfter
