import React from 'react'
import { graphql } from 'gatsby'
import { ServicesPageTemplate } from 'precision/pages/services'

const ServicesPage = ({ data }) => {
  const {
    servicesJson,
    irrigationImg2,
    irrigationImg3,
    landscapeImg1,
    landscapeImg2,
    landscapeImg3,
    landscapeImg4,
    landscapeImg5,
  } = data
  const irrigationImages = [irrigationImg2, irrigationImg3]
  const landscapeImages = [
    landscapeImg1,
    landscapeImg2,
    landscapeImg3,
    landscapeImg4,
    landscapeImg5,
  ]
  return (
    <ServicesPageTemplate
      {...servicesJson}
      irrigationImages={irrigationImages}
      landscapeImages={landscapeImages}
    />
  )
}
export default ServicesPage

export const servicesPageQuery = graphql`
  query ServicesPage {
    servicesJson {
      irrigationSection {
        title
        cards {
          title
          icon
        }
        paragraphs
      }
      landscapeSection {
        title
      }
      commonProblems
      commonRepairs
    }
    irrigationImg2: file(relativePath: { eq: "img/landscape/s5.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    irrigationImg3: file(relativePath: { eq: "img/landscape/s6.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    landscapeImg1: file(relativePath: { eq: "img/landscape/l1.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    landscapeImg2: file(relativePath: { eq: "img/landscape/l2.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    landscapeImg3: file(relativePath: { eq: "img/landscape/l3.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    landscapeImg4: file(relativePath: { eq: "img/landscape/l4.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    landscapeImg5: file(relativePath: { eq: "img/landscape/l5.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
