import React from 'react'
import {
  Card,
  Flex,
  Heading,
  Image,
  Icon,
  Text,
  HorizontalRule,
  Container,
  List,
  ListItem,
} from 'components'
import Carousel from 'react-multi-carousel'
import 'react-multi-carousel/lib/styles.css'
import BeforeAndAfter, { BeforeAndAfter2 } from 'components/BeforeAndAfter'

const responsive = {
  xl: {
    breakpoint: { max: 4000, min: 1800 },
    items: 1,
    slidesToSlide: 1, // optional, default to 1.
  },
  desktop: {
    breakpoint: { max: 1800, min: 1024 },
    items: 1,
    slidesToSlide: 1, // optional, default to 1.
  },
  tablet: {
    breakpoint: { max: 1024, min: 672 },
    items: 1,
    slidesToSlide: 1, // optional, default to 1.
  },
  mobile: {
    breakpoint: { max: 672, min: 0 },
    items: 1,
    slidesToSlide: 1, // optional, default to 1.
  },
}

const IrrigationSection = ({
  cards,
  commonProblems,
  commonRepairs,
  images,
}) => (
  <>
    <Flex bg="brand.primary" p={16} hAlignContent="center" col color="white">
      <Heading.h1 textAlign="center">
        Looking for a properly installed irrigation system?
      </Heading.h1>
      <HorizontalRule />

      <Heading.h3 textAlign="center">
        Configuring and installing an irrigation system is a complicated task.
      </Heading.h3>
    </Flex>

    <Flex wrap minHeight={400}>
      <Flex col grow width={['100%', 1 / 2]} p={32}>
        <Container grow>
          <Text>
            Precision Sprinkler Systems, Inc. provides a fully equipped service
            department, keeping all our vehicles stocked with the proper tools
            and equipment needed to service your sprinkler systems. All of our
            technicians are properly trained and will arrive at your home or
            business in logoed vehicles. We look forward to meeting your
            irrigation needs with our reputation for quality and integrity.
          </Text>
        </Container>
      </Flex>
      <Flex width={['100%', 1 / 2]}>
        <Carousel
          swipeable={false}
          draggable={false}
          showDots={true}
          responsive={responsive}
          ssr={true} // how to do SSR with gatsby?
          infinite={true}
          autoPlay={false}
          autoPlaySpeed={6000}
          keyBoardControl={true}
          customTransition="all .5"
          transitionDuration={500}
          containerClass="carousel-container"
          removeArrowOnDeviceType={['tablet', 'mobile']}
          css={{ width: '100%', height: '100%' }}
          minimumTouchDrag={500}
          removeArrowOnDeviceType={null}
        >
          <BeforeAndAfter />
          <BeforeAndAfter2 />

          {images &&
            images.map(image => <Image fluid={image.childImageSharp.fluid} />)}
        </Carousel>
      </Flex>
      <Flex hAlignContent="space-around" bg="offWhite" py={4} wrap width="100%">
        {cards.map(card => (
          <Card height={200} hAlignContent="center" col width="20em" m={8}>
            <Icon name={card.icon} size={80} />
            <Heading textAlign="center">{card.title}</Heading>
          </Card>
        ))}
      </Flex>
      <Flex width={['100%', 1 / 4]} center bg="brand.secondary" color="white">
        <Heading.h3 textAlign="center" p={16}>
          Common Repairs:
        </Heading.h3>
      </Flex>
      <Flex width={['100%', 1 / 4]} vAlignContent="center">
        <List p={16}>
          {commonRepairs.map(c => (
            <ListItem>{c}</ListItem>
          ))}
        </List>
      </Flex>
      <Flex width={['100%', 1 / 4]} bg="brand.secondary" color="white" center>
        <Heading.h3 p={16}>Frequent Problems:</Heading.h3>
      </Flex>
      <Flex width={['100%', 1 / 4]} vAlignContent="center">
        <List>
          {commonProblems.map(c => (
            <ListItem>{c}</ListItem>
          ))}
        </List>
      </Flex>
    </Flex>
  </>
)

const LandscapeSection = ({ title, images }) => (
  <>
    <Flex bg="brand.primary" p={16} hAlignContent="center" col color="white">
      <HorizontalRule />
      <Heading.h1>{title}</Heading.h1>
      {/* <Heading.h3>What can we do to help?</Heading.h3> */}
    </Flex>
    <Flex wrap minHeight={400}>
      <Flex width={['100%', 1 / 2]}>
        <Carousel
          swipeable={true}
          draggable={false}
          showDots={true}
          responsive={responsive}
          ssr={false} // how to do SSR with gatsby?
          infinite={true}
          autoPlay={false}
          autoPlaySpeed={6000}
          keyBoardControl={true}
          customTransition="all .5"
          transitionDuration={500}
          containerClass="carousel-container"
          removeArrowOnDeviceType={['tablet', 'mobile']}
          css={{ width: '100%', height: '100%' }}
        >
          {images &&
            images.map(image => <Image fluid={image.childImageSharp.fluid} />)}
        </Carousel>
      </Flex>
      <Flex col grow width={['100%', 1 / 2]} p={32}>
        <Container grow>
          <Heading.h3 textAlign="center" fontWeight="black">
            Interested in finding out what we can do for you regarding drainage,
            sod, or landscape lighting?
          </Heading.h3>
        </Container>

        <Flex col hAlignContent="center" pt={32}>
          <Icon name="phone" size={80} />
          <Heading.h2 textAlign="center">
            Give us a call to find out more
          </Heading.h2>
          <HorizontalRule />
          <Heading.h2>770-594-8135</Heading.h2>
        </Flex>
      </Flex>
    </Flex>
  </>
)

const ServicesPageTemplate = ({
  irrigationSection,
  irrigationImages,
  landscapeSection,
  commonProblems,
  commonRepairs,
  landscapeImages,
}) => (
  <>
    <IrrigationSection
      cards={irrigationSection.cards}
      commonProblems={commonProblems}
      commonRepairs={commonRepairs}
      images={irrigationImages}
    />
    <LandscapeSection title={landscapeSection.title} images={landscapeImages} />
  </>
)

export default ServicesPageTemplate
